// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-19abhot-MuiTabs-indicator {
  position: absolute;
  height: 2px;
  /* bottom: 0; */
  top: 0;
  width: 100%;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #ec4899;
}
/* Override first-letter styling */
p::first-letter,
h1::first-letter,
h2::first-letter,
h3::first-letter,
h4::first-letter,
h5::first-letter,
h6::first-letter,
li::first-letter,
span::first-letter,
div::first-letter {
  font-size: inherit !important;
  font-weight: inherit !important;
  color: inherit !important;
  text-transform: inherit !important;
  float: none !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: inherit !important;
}

/* Ensure text doesn't have drop caps or other decorative first-letter styling */
.first-letter-normal::first-letter {
  font-size: inherit !important;
  font-weight: inherit !important;
  color: inherit !important;
}

/* Target specific elements with IDs if necessary */
#root p::first-letter,
#root h1::first-letter,
#root h2::first-letter,
#root h3::first-letter,
#root h4::first-letter,
#root h5::first-letter,
#root h6::first-letter,
#root li::first-letter {
  font-size: inherit !important;
  font-weight: inherit !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,MAAM;EACN,WAAW;EAEX,sDAAsD;EACtD,yBAAyB;AAC3B;AACA,kCAAkC;AAClC;;;;;;;;;;EAUE,6BAA6B;EAC7B,+BAA+B;EAC/B,yBAAyB;EACzB,kCAAkC;EAClC,sBAAsB;EACtB,oBAAoB;EACpB,qBAAqB;EACrB,+BAA+B;AACjC;;AAEA,gFAAgF;AAChF;EACE,6BAA6B;EAC7B,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA,mDAAmD;AACnD;;;;;;;;EAQE,6BAA6B;EAC7B,+BAA+B;AACjC","sourcesContent":[".css-19abhot-MuiTabs-indicator {\n  position: absolute;\n  height: 2px;\n  /* bottom: 0; */\n  top: 0;\n  width: 100%;\n  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  background-color: #ec4899;\n}\n/* Override first-letter styling */\np::first-letter,\nh1::first-letter,\nh2::first-letter,\nh3::first-letter,\nh4::first-letter,\nh5::first-letter,\nh6::first-letter,\nli::first-letter,\nspan::first-letter,\ndiv::first-letter {\n  font-size: inherit !important;\n  font-weight: inherit !important;\n  color: inherit !important;\n  text-transform: inherit !important;\n  float: none !important;\n  margin: 0 !important;\n  padding: 0 !important;\n  line-height: inherit !important;\n}\n\n/* Ensure text doesn't have drop caps or other decorative first-letter styling */\n.first-letter-normal::first-letter {\n  font-size: inherit !important;\n  font-weight: inherit !important;\n  color: inherit !important;\n}\n\n/* Target specific elements with IDs if necessary */\n#root p::first-letter,\n#root h1::first-letter,\n#root h2::first-letter,\n#root h3::first-letter,\n#root h4::first-letter,\n#root h5::first-letter,\n#root h6::first-letter,\n#root li::first-letter {\n  font-size: inherit !important;\n  font-weight: inherit !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
